import Loadable from "@loadable/component";
import axios from "axios";
import { useFormik } from "formik";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import ReactMarkdown from "react-markdown";
import Layout from "../components/layout";
import SEO from "../components/seo";
import logo from "../images/gmarker.png";

const ReactMapGL = Loadable(() => import(`react-map-gl`));

export const ContactPageTemplate = ({ post }) => {
  const [contactSucces, setContactSucces] = useState(false);
  const [contactFail, setContactFail] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: ``,
      companyName: ``,
      website: ``,
      email: ``,
      message: ``,
    },
    onSubmit: async (values) => {
      let { name, companyName, website, email, message } = values;

      let postBody = [
        {
          id: `name`,
          name: `Naam`,
          value: name,
        },
        {
          id: `companyName`,
          name: `Bedrijfsnaam`,
          value: companyName,
        },
        {
          id: `website`,
          name: `Website`,
          value: website,
        },

        {
          id: `email`,
          name: `E-mailadres`,
          value: email,
        },
        {
          id: `message`,
          name: `Bericht`,
          value: message,
        },
      ];

      try {
        await axios.post(`https://mailer.gladior.com/mail`, postBody, {
          headers: {
            "x-api-key": `VHCx3rPEco6n3Vr3YxMNj1D1EJUaPGRb23mBqpuk`,
          },
        });
        try {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "contact",
            contactForm: {
              event: "new-contact",
              url: window.location.pathname,
            },
          });
        } catch (e) {}

        setContactSucces(true);
      } catch (e) {
        setContactFail(true);
      }
    },
  });

  const [viewport] = useState({
    longitude: 6.8890452,
    latitude: 52.2284369,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    width: 972,
    height: 900,
  });
  //marker location
  const [marker] = useState({
    latitude: 52.2284369,
    longitude: 6.8890452,
  });

  const [onClick, setOnClick] = useState(false);

  return (
    <>
      <div className="">
        <div className="flex -mx-2 items-stretch flex-wrap">
          {post.frontmatter.blocks.map((block) => {
            return (
              <div
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4  px-2"
                key={block.title}
              >
                <div className="bg-white p-8 text-center h-full rounded shadow-md">
                  <p className="font-bold text-xl mb-2 font-display">
                    {block.title}
                  </p>
                  <ReactMarkdown
                    className="netlify-html "
                    source={block.body}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-wrap flex-row mt-10">
        <div className="flex flex-col w-full md:w-1/2 p-4">
          <h4 className="text-2xl font-semibold mb-3 font-display  ">
            Gladior B.V.
          </h4>
          <div className="uppercase tracking-wide text-sm font-bold">Adres</div>
          <p className="text-base">Boddenkampsingel 87</p>
          <p className="text-base">7514 AP</p>
          <p className=" text-base">Enschede</p>
          <div className="flex  flex-col sm:flex-row">
            <ul className="flex-1">
              <li className="mb-1">
                <div className="uppercase tracking-wide text-sm font-bold pt-4">
                  KvK
                </div>
                <p className="text-base">08137991</p>
              </li>
              <li className="mb-1">
                <div className="uppercase tracking-wide text-sm font-bold pt-4">
                  BTW NUMMER NL:
                </div>
                <p className="text-base">NL8147.93.150.B01</p>
              </li>
            </ul>
            <ul className="flex-1">
              <li className="mb-1">
                <div className="uppercase tracking-wide text-sm font-bold pt-4">
                  Rekeningnr IBAN:
                </div>
                <p className="text-base">NL 34 ABNA 0568 7305 42</p>
              </li>
              <li className="mb-1">
                <div className="uppercase tracking-wide text-sm font-bold pt-4">
                  BIC
                </div>
                <p className="text-base">ABNANL2A</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-1/2 mt-10 md:mt-0 p-4 ">
          <h4 className="text-2xl font-semibold mb-3 font-display  ">
            Neem contact met ons op
          </h4>

          <form
            className="w-full max-w-lg"
            id="contact-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Naam (verplicht)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  placeholder="Voornaam Achternaam"
                  required
                  type="text"
                  value={formik.values.name}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="companyName"
                >
                  Bedrijfsnaam
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="companyName"
                  name="companyName"
                  onChange={formik.handleChange}
                  placeholder="Bedrijfsnaam"
                  type="text"
                  value={formik.values.companyName}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="website"
                >
                  Website
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="website"
                  name="website"
                  onChange={formik.handleChange}
                  placeholder="www.voorbeeld.nl"
                  type="text"
                  value={formik.values.website}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  E-mailadres (verplicht)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  placeholder="voorbeeld@website.nl"
                  required
                  type="email"
                  value={formik.values.email}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  Bericht (verplicht)
                </label>
                <textarea
                  className="resize-y appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  placeholder="Uw bericht"
                  required
                  value={formik.values.message}
                ></textarea>
              </div>
            </div>

            {contactSucces === true && (
              <div
                className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                role="alert"
              >
                <p className="font-bold text-gladior-pink">
                  Hartelijk dank voor uw bericht
                </p>

                <p className="text-gladior-pink">
                  Wij streven ernaar uw vraag binnen 5 werkdagen te
                  beantwoorden.
                </p>
              </div>
            )}

            {contactFail === true && (
              <div
                className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                role="alert"
              >
                <p className="font-bold">Onbekende fout voorgedaan </p>

                <p>Er heeft zich een onbekende fout voorgedaan.</p>
              </div>
            )}

            <button
              className="bg-transparent hover:bg-gladior-pink  text-gladior-pink  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
              type="submit"
            >
              Verzenden
            </button>
          </form>
        </div>
      </div>

      <div className="mt-8">
        <ReactMapGL
          {...viewport}
          getCursor={() => `auto`} // hoogte en breedte
          height={250}
          mapboxApiAccessToken={`pk.eyJ1IjoiZ2xhZGlvciIsImEiOiJjazQ5eGIyb3gwOWI0M21xeGhqZWU3ZHF0In0.d_vs2YwL9HJq2fxLkwgLhA`}
          mapStyle={`mapbox://styles/gladior/ck49ycb3v0wbf1dntjq53jv03`}
          onClick={() => onClick && setOnClick(false)}
          width={`100%`}
        >
          <Marker
            latitude={marker.latitude}
            longitude={marker.longitude}
            offsetLeft={-20}
            offsetTop={-58}
          >
            <img
              alt="Gladior Logo"
              onClick={() => {
                setOnClick(true);
              }}
              src={logo}
              style={{ cursor: `pointer`, height: `60px` }}
            />
          </Marker>
          {onClick && (
            <Popup
              anchor="bottom"
              closeOnClick={false}
              latitude={marker.latitude}
              longitude={marker.longitude}
              onClose={() => setOnClick(false)}
              tipSize={5}
            >
              <div>
                <a
                  className="tracking-wide text-sm"
                  href="https://www.google.nl/maps/dir//Gladior+Online+Marketing,+Boddenkampsingel+87,+7514+AP+Enschede/@52.228209,6.8891709,17z"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Boddenkampsingel 87, 7514 AP Enschede, Nederland
                </a>
              </div>
            </Popup>
          )}
        </ReactMapGL>
      </div>
    </>
  );
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        subtitle={post.frontmatter.subtitle}
        title={post.frontmatter.title}
      >
        <main className="container mx-auto px-6 py-10  flex flex-col justify-center  ">
          <ContactPageTemplate content={post.html} post={post} />
        </main>
      </Layout>
    </>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        blocks {
          title
          body
        }
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        subtitle
      }
    }
  }
`;
